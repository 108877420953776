import { defaultLocale, getInitOptions } from './i18nConfig';

import i18n from 'i18next';
import { loadTranslations } from '@justgiving/page-components';
import resourcesToBackend from 'i18next-resources-to-backend';

export const i18nInstance = i18n.createInstance();

const locale =
  typeof window === 'object'
    ? document.querySelector('html')?.getAttribute('lang') ?? defaultLocale
    : defaultLocale;

i18nInstance
  .use(
    resourcesToBackend(
      async (lang: string, ns: string) =>
        (await loadTranslations(lang, ns)) ??
        (await import(
          /* webpackChunkName: "lang/[request]" */ `../../lang/${lang}/${ns}.json`
        )),
    ),
  )
  .init(
    getInitOptions({
      lng: locale,
    }),
    (error) => {
      error && console.warn('i18n client error', error);
    },
  );
