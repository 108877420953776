export const formatNumber = (
  value: string | number,
  locale?: string,
): string => {
  let valueToFormat;

  if (typeof value == 'number') {
    valueToFormat = value;
  } else if (!isNaN(parseInt(value))) {
    valueToFormat = parseInt(value);
  }

  if (valueToFormat) {
    try {
      return new Intl.NumberFormat(locale).format(valueToFormat);
    } catch (e) {
      return value.toString();
    }
  }
  return value.toString();
};
