'use client';

import { Auth, createAuth } from '@justgiving/auth';
import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import clientConfig from '../../client/config';

const AuthContext = createContext<Auth | null>(null);
export const useAuth = () => useContext(AuthContext);

interface AuthProviderProps extends PropsWithChildren {
  value?: Auth;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({
  children,
  value,
}) => {
  const [auth, setAuth] = useState<Auth | null>(value ?? null);

  useEffect(() => {
    setAuth(
      createAuth({
        documentOrReq: document,
        authCookieName: clientConfig.authCookieName,
        activeGroupCookieName: clientConfig.activeGroupsCookieName,
        refreshAccessTokenUrl: `${clientConfig.iamUrl}/account/refresh-access-token`,
      }),
    );
  }, []);

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};
