'use client';

import { PageComponentsProvider as Provider } from '@justgiving/page-components';
import { ReactNode } from 'react';

export const PageComponentsProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  return <Provider>{children}</Provider>;
};
