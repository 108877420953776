const defaultOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
} as const;

export function formatShortDateValue(date: Date, locale: string) {
  try {
    return getFormatter(locale, {
      timeZone: 'UTC',
    }).format(date);
  } catch (e) {
    return date;
  }
}

export function formatDateValueUTC(date: Date, locale: string) {
  try {
    return getFormatter(locale, {
      ...defaultOptions,
      timeZone: 'UTC',
    }).format(date);
  } catch (e) {
    return date;
  }
}

export function formatDateValue(date: Date, locale: string) {
  try {
    return getFormatter(locale, defaultOptions).format(date);
  } catch (e) {
    return date;
  }
}

export function sanitizeDateResponse(date: string): Date {
  if (date.startsWith('/Date(')) {
    return new Date(parseInt(date.replace('/Date(', '').replace(')/', ''), 10));
  }

  return new Date(date);
}

export function getDateWithoutHours(date: Date): Date {
  const dateWithoutHours = new Date(date);
  dateWithoutHours.setUTCHours(0, 0, 0, 0);
  return dateWithoutHours;
}

export function getCurrentTimestamp(): number {
  return Date.now();
}

export function getDate(date?: number | string) {
  return date ? new Date(date) : new Date();
}

export function formatDateTime(date: Date, locale: string): string {
  const formatter = getFormatter(locale, {
    dateStyle: 'long',
    timeStyle: 'short',
  });

  return formatter.format(date);
}

export function formatDuration(durationInSeconds: number) {
  const hours = Math.floor(durationInSeconds / 3600);
  const minutes = Math.floor((durationInSeconds % 3600) / 60);
  const remainingSeconds = durationInSeconds % 60;

  if (hours > 0) {
    return `${hours}h ${minutes}m`;
  }

  if (minutes > 0) {
    return `${minutes}m ${remainingSeconds}s`;
  }

  if (remainingSeconds > 0) {
    return `${remainingSeconds}s`;
  }

  return '0m';
}

export const formatToYYYYMMDD = (date: Date) =>
  date.toISOString().split('T')[0].replace(/-/g, '');

export const addDays = (date: Date, days: number) => {
  const dayInMilliseconds = 86400000;
  return new Date(date.getTime() + dayInMilliseconds * days);
};

const formatterCache = new Map<string, Intl.DateTimeFormat>();

// Using cache as creating a new instance of Intl.DateTimeFormat is a quite heavy operation in Node.js
function getFormatter(locale: string, options: Intl.DateTimeFormatOptions) {
  const key = JSON.stringify({ locale, options });
  let formatter = formatterCache.get(key);

  if (!formatter) {
    formatter = new Intl.DateTimeFormat(locale, options);
    formatterCache.set(key, formatter);
  }

  return formatter;
}
