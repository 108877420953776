import {
  formatDateValueUTC,
  formatShortDateValue,
  sanitizeDateResponse,
} from '../utils/date';

import { InitOptions } from 'i18next';
import { formatNumber } from '../utils/formatNumber';

export const supportedLocales = ['en-GB'];
export const defaultLocale = 'en-GB';

export const getInitOptions = (init?: Partial<InitOptions>): InitOptions => ({
  debug: false,
  keySeparator: false,
  defaultNS: 'common',
  ns: ['common'],
  supportedLngs: supportedLocales,
  fallbackLng: defaultLocale,
  interpolation: {
    format: (value: string, format?: string): string => {
      if (format === 'dateUTC') {
        return `${formatDateValueUTC(
          sanitizeDateResponse(value),
          init?.lng ?? defaultLocale,
        )}`;
      }
      if (format === 'shortDateUTC') {
        return `${formatShortDateValue(
          sanitizeDateResponse(value),
          init?.lng ?? defaultLocale,
        )}`;
      }
      if (format === 'number') {
        return `${formatNumber(value, init?.lng)}`;
      }
      if (format === 'list') {
        return new Intl.ListFormat(init?.lng ?? defaultLocale).format(value);
      }
      if (format === 'lowercase') {
        return value.toLowerCase();
      }
      return value;
    },
    escapeValue: false, // React already escapes all values
  },
  ...init,
});
